import React from 'react';
import { Navigate } from 'react-router-dom';

// Assuming isAuthenticated is a boolean that indicates whether the user is logged in
const PrivateRoute = ({ children }) => {
//  const isAuthenticated = localStorage.getItem('isLoggedIn'); // Check if a token exists or other auth logic

const isAuthenticated='true';

  return isAuthenticated==='true' ? children : <Navigate to="/login" />;
};

export default PrivateRoute;