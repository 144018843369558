import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingSpinner from "./Components/LoadingSpinner";
import axios from "axios";
import PrivateRoute from './Components/PrivateRoute'; // The component you just created
import './App.css';
import {isMobile} from 'react-device-detect';
import SideNav from "./Components/SideNav.jsx";
import mixpanel from 'mixpanel-browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeagueTeamView from "./Containers/LeagueTeamView.jsx";
import Footer from "./Components/Footer.jsx";

//CONTAINERS
const Dashboard = React.lazy(() => import("./Containers/Dashboard.jsx"));
const NewHeader = React.lazy(() => import("./Components/NewHeader.jsx"));
const AppOwner = React.lazy(() => import("./Containers/AppOwner/AppOwner.jsx"));
const Home = React.lazy(() => import("./Containers/Home.jsx"));

const LoginPage = React.lazy(() => import("./Containers/LoginPage.jsx"));
const LoginModal = React.lazy(() => import("./Components/LoginModal.jsx"));
const Profile = React.lazy(() => import("./Containers/Profile.jsx"));
const Admin = React.lazy(() => import("./Containers/Admin/Admin.jsx"));
const OrgSettings = React.lazy(() => import("./Containers/Admin/OrgSettings"));
const Employees = React.lazy(() => import("./Containers/Admin/Employees.jsx"));
const Utilization = React.lazy(() => import("./Containers/Admin/Utilization.jsx"));
const ContentHub = React.lazy(() => import("./Containers/Admin/ContentHub.jsx"));
const AppProfile = React.lazy(() => import("./Containers/AppProfile.jsx"));
const GetAppModal = React.lazy(() => import("./Components/GetAppModal.jsx"));
const OrgOnboardingModal = React.lazy(() => import("./Components/OrgOnboardingModal.jsx"));
const DISCLAIMER = React.lazy(() => import("./Containers/Legal/Disclaimer.jsx"));
const PRIVACYPOLICY = React.lazy(() => import("./Containers/Legal/PrivacyPolicy.jsx"));
const COOKIEPOLICY = React.lazy(() => import("./Containers/Legal/CookiePolicy.jsx"));
const TERMSOFSERVICE = React.lazy(() => import("./Containers/Legal/TermsofService.jsx"));
const Users = React.lazy(() => import("./Containers/AppOwner/Users.jsx"));
const Performance = React.lazy(() => import("./Containers/AppOwner/Performance.jsx"));
const AppOwnerProfile = React.lazy(() => import("./Containers/AppOwner/AppOwnerProfile.jsx"));
const AssessmentWrapper = React.lazy(() => import("./Containers/AssessmentWrapper.jsx"));
const GlobalSubscriptions = React.lazy(() => import("./Containers/GlobalAdmin/GlobalSubscriptions.jsx"));
const LeagueApps = React.lazy(() => import("./Containers/LeagueApps.jsx"));

function App() {

  mixpanel.init("393f475d972dd9138f742a49ff5cfe53", {
    debug: false,
    ignore_dnt: true,
    track_pageview: 'url-with-path',
    persistence: "localStorage",
  });
  const [subscribedApp,setSubscribedApp] = useState(false);
  const [showGetAppModal,setShowGetAppModal] = useState(false);
  const [showOrgOnboarding,setShowOrgOnboarding] = useState(false);
  const [isSideNavCollapsed,setIsSideNavCollapsed] = useState();
  const [user,setUser] = useState();
  const [isLoggedIn,setIsLoggedIn] = useState();
  const [isLogInScreen,setIsLogInScreen] = useState();
  const [happbotSetup, setIsHappbotSetup] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [disableBodyScroll, setDisableBodyScroll] = useState(false);
  const [isPublicTestAccount, setIsPublicTestAccount] = useState(false);


  toast.onboarding = (body, userOptions = {}) => {

    const content = <div className="onboarding-toast">
    <div className="onboarding-toast-icon">
      <img alt='brian' className='onboarding-toast-icon-img' src="/icons/brian.png" />
    </div>
    <div className="onboarding-toast-text">
      <div className="onboarding-toast-headline">Burned-out Brian says:</div>
      <div className="onboarding-toast-body">
        {body}
      </div>
    </div>
  </div>

  const ToastCloseButton = ({ closeToast }) => (
    <button
      onClick={closeToast}
      style={{
        backgroundColor: "transparent",
        border: "none",
        color: "#fff", // Change this to your desired color
        fontSize: "16px",
        cursor: "pointer",
        display:'flex',
        alignContent:'start'
      }}
    >
      &#10005;
    </button>
  );

    const defaultToastId = "custom-toast-id"; // Default unique ID for your custom toast
  
    // Default options for the onboarding toast

    var defaultOptions = null;

    if(!isMobile)
       defaultOptions = {
        toastId: defaultToastId,
        style: {
          backgroundColor: "#0c0e31",
          width: "500px",
          height: "150px",
          borderRadius: "8px",
          color: "#fff",
        },
        position: "bottom-left",
        closeOnClick: false,
        draggable: false,
        closeButton: false,
        autoClose:false,
        onClick: () => toast.dismiss(),
        closeButton: <ToastCloseButton />        
      };
    else
      defaultOptions = {
        toastId: defaultToastId,
        style: {
          backgroundColor: "#0c0e31",
          width: "360px",
          height: "120px",
          borderRadius: "8px",
          color: "#fff"
        },
        position: "bottom-left",
        closeOnClick: false,
        draggable: false,
        closeButton: false,
        autoClose:false,
        onClick: () => toast.dismiss(),
        closeButton: <ToastCloseButton />      };
  
    // Merge default options with user-provided options
    const options = { ...defaultOptions, ...userOptions };
  
    // Check if the toast is already active
    if (!toast.isActive(options.toastId)) {
      // Display a new toast
      toast(content, options);
    } else {
      // Update the existing toast
      toast.update(options.toastId, {
        render: content, // Replace the content
        ...options, // Update with new options if provided
      });
    }
  };
  


   const toggleLoginModal = (isNudge) =>{
    if(!isMobile){
      setShowLoginModal(!showLoginModal);
    }
    else if (isNudge && !isLogInScreen && !sessionStorage.getItem("one_time_login_nudge") ){
       sessionStorage.setItem("one_time_login_nudge", "true");
        window.location.href='/login'
    }
    else if(!isNudge)
      window.location.href='/login'
     
   }

   useEffect(() => {
    if(showLoginModal)
      setDisableBodyScroll(true)
    else
      setDisableBodyScroll(false)
  
   }, [showLoginModal]);
   
const toggleGetAppModal = (app) =>{
  setSubscribedApp(app)
  setShowGetAppModal(!showGetAppModal);
 }

 const handleIsLogInScreen = (result) =>{
  setIsLogInScreen(result);
 }

 const toggleSideNav = () =>{
  if(isMobile)
  setIsSideNavCollapsed(!isSideNavCollapsed)
 }

 const toggleOrgOnboardingModal = () =>{
  setShowOrgOnboarding(!showOrgOnboarding);

 }

 const checkIfOrgComplete = () => {
  try {
      axios
        .get("/org/profile")
        .then((res) => {
          if (res.data) {
              if(!res.data.headcount){
                //DISABLE FOR NOW
                toggleOrgOnboardingModal();
              }
          
          }
        })
        .catch((error) => {
          console.log("Server error. Refresh and try again.");
        });
    } catch (e) {
      console.log("Server error. Refresh and try again.");
    } 
}

const getUserData = async () => {
  try {
    const res = await axios.get("/user");

    if (res.data && res.data.email) {

    if(!res.data.public_test_account)
      mixpanel.register({
        'Email': res.data.email,
        'isAdmin': res.data.isAdmin,
        'orgSubscribed': res.data.org.subscribed,
        'isAppOwner': res.data.appOwner,
        'domain':res.data.domain
    });

    if(!res.data.public_test_account){
      setIsPublicTestAccount(false)
      localStorage.setItem('hasEverLoggedIn', true);
    }

    else
      setIsPublicTestAccount(true)

      setUser(res.data);
      localStorage.setItem('isLoggedIn', true);
      setIsLoggedIn(true);

      if (res.data.isAdmin) {
        checkIfOrgComplete();

        if(!res.data.org.slack_enabled)
          setIsHappbotSetup(false)
      }
    } else if (res.status === 401) {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    } else {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    } else {
      console.log(error)
      console.log("Server error. Refresh.");
    }
  }
};
 
 useEffect(() => {
   if(isMobile)
      setIsSideNavCollapsed(true);

   getUserData();
 
  }, []);

      
  return (
    <div className={isLogInScreen ? ("App no-scroll"):("App")}>
      
               {false && !isLogInScreen && isPublicTestAccount && <div className='global-banner'>You are viewing a test account.<div className='global-banner-ctas'> <button className='white-ghost-button' onClick={()=>toggleLoginModal()}>Login</button>  <button className='white-ghost-button' onClick={()=>toggleLoginModal()}>Sign up</button></div></div>}

      <Suspense fallback={<LoadingSpinner />}>
          <Router>
         {!isLogInScreen && isPublicTestAccount && <LoginModal toggleLoginModal={toggleLoginModal} showLoginModal={showLoginModal}></LoginModal>}
         {!isLogInScreen && isPublicTestAccount && !showLoginModal && <ToastContainer />}
         {isMobile && <SideNav isMobile={isMobile} isPublicTestAccount={isPublicTestAccount} toggleSideNav={toggleSideNav} isSideNavCollapsed={isSideNavCollapsed} user={user}/>}

            <GetAppModal user={user} subscribedApp={subscribedApp} showGetAppModal={showGetAppModal} toggleGetAppModal={toggleGetAppModal} />
            <OrgOnboardingModal user={user} showOrgOnboarding={showOrgOnboarding} toggleOrgOnboardingModal={toggleOrgOnboardingModal} />
         {!isLogInScreen && <NewHeader toggleSideNav={toggleSideNav} isPublicTestAccount={isPublicTestAccount} toggleLoginModal={toggleLoginModal} isMobile={isMobile} user={user}></NewHeader>}


            <div className='page-container'>          
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path="/login" element={<LoginPage handleIsLogInScreen={handleIsLogInScreen} user={user} isLoggedIn={isLoggedIn} isLogInScreen={isLogInScreen}  />} />
              <Route path="/policies/disclaimer" element={<DISCLAIMER />} />
              <Route path="/policies/privacypolicy" element={<PRIVACYPOLICY />} />
              <Route path="/policies/cookiepolicy" element={<COOKIEPOLICY />} />
              <Route path="/policies/tos" element={<TERMSOFSERVICE />} />

              {/* PRIVATE ROUTES */}
              <Route path="/apps" element={<PrivateRoute><Dashboard isMobile={isMobile} toggleGetAppModal={toggleGetAppModal} user={user} /></PrivateRoute>} />
              <Route path="/" element={<PrivateRoute><Home isMobile={isMobile} toggleLoginModal={toggleLoginModal}  /></PrivateRoute>} />
              <Route path="/leagues" element={<PrivateRoute><LeagueApps toggleLoginModal={toggleLoginModal} isPublicTestAccount={isPublicTestAccount} user={user} /></PrivateRoute>} />
              <Route path="/leagues/*" element={<PrivateRoute><LeagueTeamView user={user} /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile user={user}  /></PrivateRoute>} />
              <Route path="/app/*" element={<PrivateRoute><AppProfile user={user} toggleGetAppModal={toggleGetAppModal} /></PrivateRoute>} />
              <Route path="/admin" element={<PrivateRoute><Admin toggleLoginModal={toggleLoginModal} user={user}/></PrivateRoute>} />
              <Route path="/admin/settings" element={<PrivateRoute><OrgSettings toggleLoginModal={toggleLoginModal} user={user}/></PrivateRoute>} />
              <Route path="/admin/utilization" element={<PrivateRoute><Utilization user={user} /></PrivateRoute>} />
              <Route path="/admin/employees" element={<PrivateRoute><Employees user={user} /></PrivateRoute>} />
              <Route path="/admin/contenthub" element={<PrivateRoute><ContentHub user={user} /></PrivateRoute>} />
              <Route path="/owner/" element={<PrivateRoute><AppOwner user={user}/></PrivateRoute>} />
              <Route path="/owner/profile" element={<PrivateRoute><AppOwnerProfile user={user}/></PrivateRoute>} />
              <Route path="/owner/users" element={<PrivateRoute><Users user={user} /></PrivateRoute>} />
              <Route path="/owner/performance" element={<PrivateRoute><Performance user={user} /></PrivateRoute>} />
              <Route path="/match" element={<PrivateRoute><AssessmentWrapper isMobile={isMobile} handleIsLogInScreen={handleIsLogInScreen} user={user} /></PrivateRoute>} />
              <Route path="/globaladmin/subscriptions" element={<PrivateRoute><GlobalSubscriptions  isMobile={isMobile} user={user} /></PrivateRoute>} />

            </Routes>
           
            </div>
            <Footer/>
          </Router>
          
        </Suspense>
        
    </div>
  );
}

export default App;
